<template>
  <div>
    <b-nav card-header pills class="mt-1 ml-1 mb-0">
      <b-nav-item
        v-for="(tab, index) in tabs"
        :key="index"
        :to="{ name: tab.route }"
        exact
        exact-active-class="active"
        :link-classes="['sub-tab-nav h-full', 'px-3', bgTabsNavs]"
      >
        {{ tab.title }}
        <span v-if="tab.total > 0" class="ml-1">
          <b-badge pill variant="danger">
            {{ tab.total }}
          </b-badge>
        </span>
      </b-nav-item>
    </b-nav>
    <b-card no-body class="border-3 border-table-radius px-0">
      <router-view :key="$route.name" />
    </b-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      tabs: [
        {
          title: "Pending",
          key: 1,
          done: 0,
          route: this.$route.matched[2].meta.routePendingLead,
          total: 0,
        },
        {
          title: "For Approval",
          key: 2,
          done: 1,
          route: this.$route.matched[2].meta.routeForApprovedLead,
          total: 0,
        },
        {
          title: "Done",
          key: 3,
          done: 2,
          total: 0,
          route: this.$route.matched[2].meta.routeDoneLead,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      G_SALE_LEADS_COUNTER: "CrmSaleMadeStore/G_SALE_LEADS_COUNTER",
    }),
  },
  watch: {
    G_SALE_LEADS_COUNTER: {
      handler(value) {
        this.tabs.forEach((item) => {
          item.total = value && item.key == 2 ? value.total : 0;
        });
      },
      deep: true,
      immediate: true,
    },
  },
  created(){
    this.getPrograms();
  },
  methods: {
    ...mapActions({
      getPrograms: "CrmSaleMadeStore/A_GET_PROGRAMS",
    }),
  },
};
</script>

<style scoped></style>
